import { template as template_e1475c4f8c5b4b33832ddc2944290cab } from "@ember/template-compiler";
const WelcomeHeader = template_e1475c4f8c5b4b33832ddc2944290cab(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
