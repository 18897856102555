import { template as template_e8c85504297c482f9760334191c04fc4 } from "@ember/template-compiler";
const SidebarSectionMessage = template_e8c85504297c482f9760334191c04fc4(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
